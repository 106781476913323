<template>
  <b-row>
    <b-col cols="3">
      <TreeView :setDong="setDong" :selected="dongSearch == '' ? '전체' : dongSearch"></TreeView>
    </b-col>
    <b-col cols="9">
      <b-card no-body class="mb-4">
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">민원/하자 요청 목록 (총 요청 건수 10건)</h5>
        </b-card-body>

        <b-table
          responsive
          class="mb-0 p-3"
          head-variant="light"
          :items="items"
          :current-page="currentPage"
          :per-page="perPage"
          :fields="fields"
        >

        <template #cell(title)="data">
          <a style='font-weight: 700; cursor: pointer;' @click="$router.push('/requestView')">
          {{data.item.title}}
          </a>
        </template>

        <template #cell(status)="data">
          <b-badge
            v-if="(data.item.status == 1)"
            pill
            :class="`px-2 text-white badge bg-primary`"
          >
            요청
          </b-badge>
          <b-badge
            v-if="(data.item.status == 2)"
            pill
            :class="`px-2 text-white badge bg-success`"
          >
            요청 확인
          </b-badge>
          <b-badge
            v-if="(data.item.status == 3)"
            pill
            :class="`px-2 text-white badge bg-danger`"
          >
            처리 예약
          </b-badge>
          <b-badge
            v-if="(data.item.status == 4)"
            pill
            :class="`px-2 text-white badge bg-secondary`"
          >
            처리 완료
          </b-badge>
        </template>

        </b-table>
        <div class="row p-3 m-0">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            class="my-0"
          ></b-pagination>
        </div>
      </b-card>
    </b-col>

  </b-row>
  
</template>

<style>
.b-table {
  border: 1px solid #eee
}
</style>

<script>
import TreeView from '../components/apps/TreeView';
export default {
  name: "Request",

  data: () => ({
    page: {
      title: "PaginationTable",
    },
    fields: [
      {
        key: "target",
        label: "요청 세대",
      },
      {
        key: "title",
        label: "제목",
      },
      {
        key: "date",
        label: "신청일",
      },
      {
        key: "req_end",
        label: "처리기한",
      },
      {
        key: "status",
        label: "상태",
      }
    ],
    items: [
      {idx: "1", target: "101동 1301호", title: "놀이터에서 담배 못피게 했으면 좋겠어요", date: "22.11.22", req_end: "22.11.29", status: 1},
      {idx: "2", target: "101동 1301", title: "현관 센서등 교체 요청 건", date: "22.11.16", req_end: "22.11.25", status: 3},
      {idx: "3", target: "508동 602호", title: "저희 아파트는 스마트홈 이용이 불가인가요?", date: "22.11.08", req_end: "22.11.15", status: 2},
      {idx: "4", target: "203동 1001호", title: "인터폰 고장 발생", date: "22.08.03", req_end: "22.08.11", status: 3},
      {idx: "5", target: "302동 702호", title: "위층이 너무 시끄러워요", date: "22.05.13", req_end: "22.05.20", status: 4},
      {idx: "6", target: "407동 302호", title: "고양이 먹이통 처리", date: "22.04.02", req_end: "22.04.09", status: 4},
      {idx: "7", target: "502동 1602호", title: "화장실에서 물이 새요", date: "22.01.18", req_end: "22.01.25", status: 4},
      {idx: "8", target: "208동 304호", title: "주차장 확장 요청", date: "21.11.26", req_end: "21.12.03", status: 3},
      {idx: "9", target: "103동 203호", title: "담배 연기", date: "21.08.19", req_end: "21.08.26", status: 4},
      {idx: "10", target: "505동 1303호", title: "강아지 입마개좀 해주세요", date: "21.04.11", req_end: "22.04.18", status: 2},
      {idx: "11", target: "205동 802호", title: "페인트 냄새가 안빠져요", date: "21.01.08", req_end: "21.01.15", status: 4}
    ],
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    dongSearch: "",

  }),
  components: {TreeView},
  methods: {
    setDong(dong, isChild){
      if(isChild){
        this.dongSearch = dong;
      }
      else{
        this.dongSearch = "";
      }
    }
  },
  mounted() {
    this.totalRows = this.items.length;
  },
};
</script>
